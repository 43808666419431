.sf-sequence-tab-view {
  background-color: var(--color-default-background);
  border-radius: var(--border-radius-regular);
  padding: 50px 130px 70px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &--gated {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='20%2c 20' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
    display: flex;
    flex-direction: column;
    align-items: center;

    .sf-sequence-tab-view {
      &__header {
        //margin-bottom: 114px;
      }
    }
  }

  &__header {
    padding: 0 50px;
  }

  &__warning {
    margin-bottom: 40px;
  }

  &__gated-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 100%;
    justify-content: center;
  }

  &__gated-content-icon {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 100%;
    background: #F4F3F5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  &__gated-content-link {
    color: var(--color-service-green);
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition-regular);

    &:hover {
      color: var(--color-service-green-hover);
      text-decoration: none;
    }
  }

  &__nav-buttons {
    display: flex;
    gap: 12px;
    margin-top: 10px;
    margin-left: 50px;
  }

  &__numbers {
    color: var(--color-gray-3);
  }

  &__title {
    margin-bottom: 10px;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-gray-2);
    margin-bottom: 10px;
  }

  &__subtitle-flag {
    fill: var(--color-violet);
    margin-left: 6px;
  }
}
