.sf-courseware-page {
  background-color: var(--color-gray-6);

  .sf-page {
    &__header, &__footer {
      background: transparent;
    }
    &__content {
      //padding: 16px 60px;
      //display: flex;
      //justify-content: space-between;
      //gap: 30px;
      display: flex;
      flex-direction: column;
      flex: 1 0 100%;
      //height: 0;
    }
  }

  &__courseware-container {
    flex: 1 0 100%;
    margin: 22px 60px 70px 140px;
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 100%;
    margin-top: 24px;
    margin-left: 140px;
    margin-right: 70px;

    .sf-breadcrumbs__list {
      flex-wrap: wrap;
    }
  }

  &__modules-button {
    min-width: 50px;
    min-height: 50px;
    position: sticky;
    top: 24px;
  }

  &__sequence-tab-view {
    width: 100%;
  }

  .sf-course-menu {
    width: 100%;
    background-color: transparent;

    &__item {
      &:hover {
        background-color: var(--color-gray-7);
        box-shadow: none;
      }
      &--selected {
        background-color: var(--color-gray-6);
        box-shadow: none;
      }
    }
  }

  &__modules-sidebar {
    .sf-sidebar__title {
      color: var(--color-coal);
    }

    .sf-sidebar__content {
      .sf-modules-menu__section-name {
        color: var(--color-coal);
      }
      height: calc(100% - 112px);
      padding-bottom: 16px;
    }
  }

  // TODO make it element and not block
  .sf-footer-component {
    background: transparent;
  }
}
