.sf-subsection-list {
  padding: 0;
  list-style: none;

  &__item {
    margin: 0;
    padding-left: 23px;

    &:not(:last-of-type) {
      .sf-subsection {
        border-bottom: 1px solid var(--color-gray-5);
      }
    }
  }
}
