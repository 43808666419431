.alert-content.lock-paywall-container {
    display: inline-flex;
}

.lock-paywall-container svg {
    color: $primary-700;
}

.fa-li {
    left: -31px !important;
    padding-right: 22px;
    top: 0;
}

    @media only screen and (min-width: 992px) and (max-width: 1100px) {
        .list-div {
            width: 62%;
    }
}
