.notification-trigger-btn {
    border: 1px solid $light-400;
    background: none;
    margin-top: 1rem;

    position: absolute;
    right: 0;

    @media (max-width: -1 + map-get($grid-breakpoints, 'sm')) {
        border: none;
        margin: 0.3rem 1.25rem 0 0.25rem;
        top: 0.1rem;
        right: -3rem;
    }
}

.trigger-active::after {
    content: '';
    position: absolute;
    border-bottom: 2px solid $primary-700;
    right: 0;
    left: 0;
    bottom: -1px;
}