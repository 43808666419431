.sf-exam-timer {
  background-color: var(--color-white);
  border-radius: var(--border-radius-regular);
  box-shadow: var(--box-shadow-m);
  padding: 24px 14px 20px;

  &__title {
    color: var(--color-gray-3);
    margin-bottom: 12px;
    display: block;
  }

  &__digits {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 24px;
  }

  &__digit-group {
    display: flex;
    gap: 2px;
    flex: 0 0 auto;
  }

  &__separator {
    flex: 0 0 auto;
  }
}

.sf-exam-timer-digit {
  background: var(--color-gray-6);
  border-radius: 5px;
  padding: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  width: 28px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
