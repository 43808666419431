.dashboard-section {
  &__header {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title-and-icon {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    line-height: var(--line-height-title-4);
    font-size: var(--font-size-title-4);
    font-weight: var(--font-weight-medium);
    margin: 0;
  }

  &__subtitle {
    line-height: var(--line-height-title-6);
    font-size: var(--line-height-title-6);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-2);
    margin-top: 24px;
    display: block;
  }

  &__icon {
    width: 36px;
    height: 36px;
    margin-right: 24px;
  }
}
