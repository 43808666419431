.register-success-page {
  .sf-page {
    &__content {
      padding: 110px 60px;
    }
  }

  &__title {
    color: var(--color-coal);
    margin-bottom: 20px;
  }

  &__msg {
    margin-bottom: 48px;
  }
}

