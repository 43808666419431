.notification-tray-container {
    border: 1px solid $light-400;
    border-radius: 4px;
    width: 31rem;
    vertical-align: top;
    height: 100%;

    @media (max-width: -1 + map-get($grid-breakpoints, 'lg')) {
        position: fixed; 
        top: 0; 
        bottom: 0; 
        left: 0; 
        right: 0;
        width: 100%;
        background-color: white;
        margin: 0;
        border: none;
        border-radius: 0;
    }
}

.no-notification {
    height: 15rem;
}

.notification-tray-title {
    display: inline-block;
    padding: 0.625rem 0 0.625rem 1.25rem;
}

.close-btn {
    float: right;
    margin-right: 0.5rem;
    margin-top: 0.35rem;
}

.notification-tray-divider {
    height: 0.5rem;
    background: $gray-100;
    border-top: 1px solid $light-400;
    border-bottom: 1px solid $light-400;
}

.notification-tray-content {
    padding: 1rem;
    font-size: 0.875rem;
}

.mobile-close-container {
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $light-400;

    span {
        display: inline-block;
    }
    svg {
        top: 0.4rem;
        left: 0.8rem;
    }
}

.mobile-close {
    font-weight: 500;
    margin-left: 1.2rem;
}