.dashboard {
  &__recent-course-section {
    padding: 0;
  }

  &__recent-course-banner {
    .sf-recent-course-banner{
      &__course-button {
        &:hover {
          color: var(--color-white);
          text-decoration: none;
        }
      }

      &__continue-button {
        &:hover {
          color: var(--color-coal);
          text-decoration: none;
        }
      }
    }
  }

  &__demo-section {
    margin-top: 40px;
    margin-bottom: 55px;

    .sf-button:hover {
      text-decoration: none;
      color: var(--color-white);
    }
  }

  &__my-courses-section {
    margin-top: 55px;
    margin-bottom: 44px;
  }

  &__course-card{
    &:hover {
      text-decoration: none;
    }

    .sf-course-card__continue-button {
      &:hover {
        color: var(--color-coal);
        text-decoration: none;
      }
    }
  }

  &__two-courses {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    .dashboard__course-card {
      flex: 1 1 auto;

      .sf-course-card__viewed-lessons {
        margin-left: 50px;
      }
    }
  }

  &__slider {
    margin-top: -5px;
    margin-bottom: -5px;

    .swiper-wrapper {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  &__project-factory-section {
    background-color: var(--color-gray-6);
    border-radius: var(--border-radius-regular);
    overflow: hidden;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    height: 353px;
  }

  &__project-factory-section-text {
    padding: 49px 56px 40px 40px;
    width: 100%;
  }

  &__project-factory-section-image {
    width: 517px;
    height: auto;
  }

  &__project-factory-title {
    font-size: var(--font-size-text-1);
    line-height: var(--line-height-text-1);
    color: var(--color-violet) !important;
    font-weight: var(--font-weight-medium);
    margin-bottom: 32px;
  }

  &__project-factory-slogan {
    font-size: var(--font-size-title-4);
    line-height: var(--line-height-title-4);
    font-weight: var(--font-weight-medium);
    margin-bottom: 62px;
  }

  &__project-factory-button {
    display: inline-flex;
    color: var(--color-white);

    &:hover {
      text-decoration: none;
      color: var(--color-white);
    }
  }

  &__career-center-section {
    margin-bottom: 60px;
  }
}
