.sf-outline-page {
  background-color: var(--color-dark-green);
  background-image: url("../../assets/images/outline-background-shape.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 60% auto;
  display: flex;

  &.sf-page {
    .sf-footer-component {
      margin: 0 16px;
    }
  }

  .sf-page {
    &__header {
      background: transparent;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .sf-loader-wrapper .sf-loader {
    stroke: var(--color-white);
  }

  &__outline-container {
    background-color: var(--color-default-background);
    border-radius: var(--border-radius-regular) var(--border-radius-regular) 0 0;
    padding: 40px 44px 108px;
    margin: 0 16px;
    height: 100%;
    flex: 1 0 auto;
    position: relative;
  }

  &__instructor-toolbar {
    position: relative;

    .instructor-toolbar {
      padding: 0 60px;
    }
  }

  &__outline-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__loader {
    justify-self: center;
  }


  &__course-menu {
    flex: 0 0 306px;
    margin-right: 47px;
  }

  &__subsection-list {
    width: 100%;
    max-width: 1000px;
  }

  &__aside {
    margin-left: 32px;
  }

  &__projects-banner {
    margin-bottom: 20px;
  }

  &__slack-link {
    display: flex;
    align-items: center;
    background-color: var(--color-gray-6);
    border-radius: 20px;
    padding: 24px 18px;
    color: inherit;
    transition: background-color var(--transition-regular);

    &:hover {
      color: inherit;
      text-decoration: none;
      background-color: var(--color-gray-5);
    }
  }

  &__slack-link-icon {
    margin-right: 20px;
  }

  &__slack-link-arrow {
    margin-left: auto;
  }
}
