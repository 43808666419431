.career-card {
  background-color: var(--color-gray-6);
  padding: 28px 24px 32px;
  border-radius: 20px;
  width: 300px;
  height: 277px;
  display: block;
  color: inherit;
  transition: 0.1s transform ease-in-out;

  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    transform: scale(1.03);
  }

  &__image {
    width: 60px;
    height: 60px;
    margin-bottom: 43px;
    transform: rotate(-90deg);
  }

  &__title {
    font-size: var(--font-size-title-6);
    line-height: var(--line-height-title-6);
    margin-bottom: 10px;
  }

  &__description {
    font-size: var(--font-size-text-5);
    line-height: var(--line-height-text-5);
  }

  &--even {
    .career-card__image {
      transform: none;
    }
  }
}
