.sf-projects-banner {
  display: block;
  background-color: #7000DF;
  position: relative;
  width: 306px;
  height: 264px;
  border-radius: 20px;

  background-image: url("../../../assets/images/projects-banner-img.png");
  background-position: right bottom;
  background-size: 230px auto;
  background-repeat: no-repeat;
  padding: 24px 39px 24px 24px;

  &__title {
    color: var(--color-light-purple);
    font-size: var(--font-size-text-2);
    line-height: var(--line-height-text-2);
    font-weight: var(--font-weight-medium);
    margin-bottom: 8px;
  }

  &:hover {
    text-decoration: none;
  }
}
