.instructor-toolbar {
  padding: 0 60px;
  background-color: var(--color-service-green-hover) !important;

  .dropdown-menu {
    background-color: var(--color-white);
    .dropdown-item {
      color: var(--color-coal);
      &:hover {
        background-color: var(--color-gray-6);
      }
      &.active {
        color: var(--color-white);
        background-color: var(--color-service-green-hover);
      }
    }
  }

  .btn-inverse-outline-primary:hover {
    color: var(--color-coal-shared);
  }

  &--transparent {
    background-color: rgba(170, 252, 78, 0.1) !important;
    backdrop-filter: blur(45px);
  }

  &__menu {
    max-height: 300px;
    overflow-y: auto;
  }
}

