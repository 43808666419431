.sf-unit-tab {
  background: var(--color-gray-5);
  border-radius: 2px;
  padding: 4px 10px 3px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  height: 8px;
  transition: height .2s, background var(--transition-regular);
  align-items: center;

  &:hover {
    background: var(--color-gray-4);
  }

  &--completed {
    background: var(--color-lime);

    &:hover {
      background: var(--color-lime-hover);
    }
  }

  &--current {
    height: 23px;
    min-width: 106px;

    &:hover {
      background: var(--color-gray-5);
    }

    .sf-unit-tab__text {
      animation: fade-in .4s forwards;
    }

    &.sf-unit-tab--completed {
      &:hover {
        background: var(--color-lime);
      }
    }
  }
}

.sf-unit-tab-tooltip {
  display: flex;
  flex-direction: column;

  &__title {
    color: var(--color-white);
    margin-bottom: 2px;
  }

  &__tasks {
    color: var(--color-white);
    opacity: 0.7;
  }
}
