.sf-auth-page-layout {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background-image: url("../../assets/images/auth-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  display: flex;
  flex-direction: column;

  &--has-subtitle {
    &__title {
      margin-bottom: 16px;
    }
  }

  &__logo {
    path {
      fill: var(--color-coal-shared);
    }
  }

  &__main {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  &__content {
    background: var(--color-white);
    flex: 0 1 644px;

    box-shadow: var(--box-shadow-l);
    border-radius: var(--border-radius-regular);
    padding: 50px 113px;

    @media (max-width: 800px) {
      padding: 50px 30px;
    }
  }

  &__title {
    color: var(--color-coal);
    margin-bottom: 30px;
    text-align: center;
  }

  &__subtitle {
    color: var(--color-coal);
    display: block;
    margin-bottom: 24px;
    text-align: center;
  }

  &__bottom-left {

  }

  &__bottom-right {

  }

  &__link {
    color: var(--color-white-shared);
    font-size: var(--font-size-text-4);
    line-height: var(--line-height-text-4);
    font-weight: var(--font-weight-medium);
    &:hover {
      color: var(--color-white-shared);
      text-decoration: none;
    }
  }

  &__header, &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 60px;
  }

  &__input {
    margin-bottom: 16px;
    width: 100%;
    input {
      width: 100%;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__note {
    display: block;
    color: var(--color-gray-3);
    margin-top: 20px;
    font-size: var(--font-size-text-5);
    line-height: var(--line-height-text-5);
  }

  &__note-link {
    color: var(--color-service-green);
    &:hover {
      color: var(--color-service-green-hover);
      text-decoration: none;
    }
  }

  &__error-msg {
    margin-top: 20px;
    color: var(--color-service-red);
  }

  &__submit-btn {
    margin-top: 32px;
    width: 100%;
  }

  &__cross-btn {
    background: rgba(36, 36, 36, 0.1);
    &:hover, &:active {
      background: rgba(36, 36, 36, 0.2);
    }
  }

  &__footer-text {
    color: var(--color-white-shared);
    opacity: 0.5;
  }
}
