.sequence-tab-view-navigation {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1px;
  position: relative;

  &__button-with-fade {
    flex: 0 0 100%;
    position: absolute;
    z-index: 100;
    right: 0;
    background: linear-gradient(270deg, #FEFEFE 27.89%, rgba(254, 254, 254, 0) 100%);
    pointer-events: none;
    width: 90px;
    display: flex;
    justify-content: flex-end;

    &--first {
      transform: rotate(180deg);
      left: 0;
      right: auto;
    }
  }

  &__arrow-button {
    flex: 0 0 auto;
    pointer-events: auto;
  }

  &__tabs-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: transform var(--transition-regular);
    min-height: 23px;
  }

  &__tab {
    flex: 1 1 100%;
  }
}
