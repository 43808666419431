.sf-instructions-page-layout {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  &__logo {
    path {
      fill: var(--color-coal-shared);
    }
  }

  &__main {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 60px;
  }

  &__content {
    background: var(--color-white);
    flex: 0 1;
    width: 1100px;

    box-shadow: var(--box-shadow-l);
    border-radius: var(--border-radius-regular);
    padding: 50px 113px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 1100px) {
      padding: 50px 30px;
    }
  }

}
