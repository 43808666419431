.sf-telegram-binding-page {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__logo {
    path {
      fill: var(--color-coal-shared);
    }
  }

  &__header {
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.375em 3.75em;
  }
}

@media screen and (max-width: 550px) {
  .sf-telegram-binding-page {
    &__header {
      padding: 5vw 1.25em;
    }
  }
}
