.sf-login-page {
  &__oauth-providers {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    gap: 16px;
  }

  &__sso-link {
    display: flex;
    border-radius: var(--border-radius-regular);
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 18px;
    width: 100%;
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-default);
    line-height: var(--line-height-default);
    background-color: #e53224;
    color: var(--color-white);
    transition: var(--transition-default-button);
    height: 60px;

    &:hover {
      color: var(--color-white);
      text-decoration: none;
      background-color: #c92417;
    }
  }

  &__sso-link-icon {
    transform: scale(1.3);

    > circle {
      fill: #fff;
    }

    > path {
      fill: #e53224;
    }
  }
}
