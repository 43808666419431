.sf-cost-info-page {


  &__link {
    color: var(--color-lime);
    &:hover {
      color: var(--color-lime);
      text-decoration: underline;
    }

  }
}
