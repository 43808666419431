.reset-password-success-page {
  .sf-auth-page-layout {
    &__title, &__subtitle {
      color: var(--color-coal);
      text-align: left;
    }
  }

  &__list-title, &__list {
    color: var(--color-gray-2);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-text-5);
    line-height: var(--line-height-text-5);
  }

  &__link {
    color: var(--color-service-green);
    &:hover {
      color: var(--color-service-green-hover);
      text-decoration: none;
    }
  }

  &__list-item {
    margin-bottom: 10px;
  }
}