.sf-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 1100px;

  &__main {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .sf-header {
    z-index: 2;
  }

  .sf-footer-component {
    
    background-color: var(--color-white);
    width: auto;

    &--rounded {
      border-radius: var(--border-radius-regular) var(--border-radius-regular) 0 0;
    }
  }

  &__content {
    flex: 1 0 100%;
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__sticky-navigation-wrapper {
    position: sticky;
    bottom: 0;
    height: 0;
    flex: 0 0 0;
  }

  &__sticky-navigation {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    transform: translateY(-100%);
    position: absolute;
    padding: 24px;
    right: 0;
    width: 100%;
  }

  &__homework-panel {
    margin-left: 50%;
    transform: translateX(-50%);
    z-index: var(--z-index-highest);
  }

  &__right {
    margin-left: auto;
  }

  &__chat-icon {
    z-index: var(--z-index-highest);
  }

  &__chat-icon-tooltip {
    z-index: var(--z-index-highest);
  }

  &__slack-link {
    margin-bottom: 13px;
  }

  // FIXME
  .sf-popover-background {
    &--visible {
      .sf-sidebar {
        transform: none;
      }
    }
  }
}

.sf-popover {
  z-index: var(--z-index-highest) !important;
}

.sf-tora-panel {
  z-index: 1 !important;
}
