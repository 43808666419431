.grade-bar {
  rect {
    height: 6px;
  }

  .grade-bar__base {
    fill: var(--color-gray-6);
  }

  .grade-bar__divider {
    fill: $primary-500;
    width: 1px;
  }

  .grade-bar--passing {
    fill: var(--color-gray-5);
  }

  .grade-bar--current-passing {
    fill: $success-500;
  }

  .grade-bar--current-non-passing {
    fill: $accent-b;
  }
}

.arrow {
  margin: 0 !important;
}

#minimum-grade-tooltip {
  border: none;
  filter: none;
  background-color: rgba(36, 36, 36, 0.8) !important;
  border-radius: var(--border-radius-small);

  .arrow {
    &::before {
      border: none;
    }

    &::after {
      border-bottom-color: rgba(36, 36, 36, 0.8);
    }
  }
}

#passing-grade-tooltip {
  .arrow::after {
    border-top-color: $success-500;
  }

  background: rgba(36, 36, 36, 0.8);
}

#non-passing-grade-tooltip {
  border: none;
  filter: none;
  background: var(--color-lime);
  border-radius: var(--border-radius-small);

  .arrow {
    &::before {
      border: none;
    }

    &::after {
      border-top-color: var(--color-lime);
    }
  }
}
