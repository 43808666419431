// Hack to improve rule's priority. We need it because of paragon's (EDX theme) styles
.sf-outline-page__outline-container > .sf-course-tabs {
  margin-bottom: 42px;
}

.sf-course-tabs {
  display: flex;
  align-items: center;
  gap: 6px;

  &__tab {
    padding: 19px 20px 17px;
    color: var(--color-coal);
    border-radius: var(--border-radius-small);
    font-size: var(--font-size-text-3);
    line-height: var(--line-height-text-3);
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;

    &--active, &:hover {
      color: var(--color-coal);
      text-decoration: none;
      background-color: var(--color-gray-6);
    }

    &--dropdown {
      border-radius: var(--border-radius-regular);
      padding: 19px 14px;
    }
  }

  &__more-btn-dropdown {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    overflow: hidden;
    padding: 6px;
    background-color: var(--color-default-background);
    z-index: var(--z-index-overlay);
    transform: translateY(4px);
    box-shadow: var(--box-shadow-s);
    border-radius: 12px;
  }
}
