.outline-header {
  color: var(--color-lime);
  padding: 32px 60px 40px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;

  &__loader-container {
    width: 100%;
    height: 317px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__course-name {
    margin-bottom: 36px;
    display: block;
  }

  &__last-lesson {
    color: inherit;
    margin-bottom: 67px;
  }

  &__widgets {
    display: flex;
    gap: 10px;
    align-self: flex-end;

    // FIXME
    h2 {
      color: inherit;
    }
  }

  &__wrapped-widget {
    .sf-info-widget__description--wrapped {
      max-width: 80% !important;
    }
  }

  &__not-wrapped-widget {
    .sf-info-widget__description--wrapped {
      max-width: initial !important;
    }
  }

  &__link {
    display: inline-flex;

    &:hover {
      text-decoration: none;
      color: var(--color-coal);
    }
  }
}