.sf-forbidden-page {

  &__container {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
  }

  &__image {
    height: 350px;
  }

}
