$hover-transition: color 0.2s ease-in-out;

.sf-subsection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: var(--color-coal);
  transition: $hover-transition;
  position: relative;
  padding-bottom: 32px;
  padding-top: 32px;

  &:hover {
    text-decoration: none;

    .sf-subsection {
      &__title, &__number, &__number-of-tasks  {
        color: var(--color-service-green);
      }
    }
  }

  &__title {
    color: var(--color-coal);
    transition: $hover-transition;
    font-size: var(--font-size-text-1);
    line-height: var(--line-height-text-1);
    font-weight: var(--font-weight-medium);
    margin-bottom: 8px;
  }

  &__number {
    color: var(--color-gray-3);
    transition: $hover-transition;
  }

  &__number-of-tasks {
    color: var(--color-gray-3);
    font-size: var(--font-size-text-2);
    line-height: var(--line-height-text-2);
    transition: $hover-transition;
  }

  &__current-lesson-tag {
    margin-top: 12px;
    width: fit-content;
  }

  &__status {
    color: var(--color-coal) !important;
  }

  &__indicator {
    margin-right: 16px;
    position: absolute;
    left: 0;
    top: 42px;
    background-color: var(--color-violet);
    font-weight: var(--font-weight-medium);
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  &--indicated {
    .sf-subsection {
      &__title, &__number {
        color: var(--color-violet);
      }
    }
  }

  &__status {
    flex: 0 0 auto;
    margin-right: 32px;
  }
}
